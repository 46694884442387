<template>
  <div id="app">
    <div>
      {{ nowUIStatusTxt }}
      <div>{{ !signIn ? "未登录" : nowUIStatusTxt }}</div>
      <div v-if="signIn">{{ statusTime | filterTime }}</div>
    </div>
    <div style="padding: 20px; width: 40%">
      <div
        style="
          flex: 3;
          justify-content: space-between;
          display: flex;
          margin-bottom: 40px;
        "
      >
        <Button type="primary" @click="onClick('SignIn')" size="small">{{
          signInDesc
        }}</Button>
        <Button
          :disabled="btn1"
          type="primary"
          @click="onClick('SetBusy')"
          size="small"
          >示忙</Button
        >
        <Button
          :disabled="btn2"
          type="primary"
          @click="onClick('SetIdle')"
          size="small"
          >示闲</Button
        >
        <Button
          :disabled="btn3"
          type="primary"
          @click="onClick('MakeCall')"
          size="small"
          >呼出</Button
        >
        <Button
          :disabled="btn4"
          type="primary"
          @click="onClick('Hold')"
          size="small"
          >保持</Button
        >
        <Button
          :disabled="btn5"
          type="primary"
          @click="onClick('RetrieveHold')"
          size="small"
          >接回</Button
        >
        <Button
          :disabled="btn6"
          type="primary"
          @click="onClick('Disconnect')"
          size="small"
          >挂断</Button
        >
        <Button
          :disabled="btn8"
          type="primary"
          @click="onClick('Conference')"
          size="small"
          >会议</Button
        >
        <Button
          :disabled="btn9"
          type="primary"
          @click="onClick('Answer')"
          size="small"
          >应答</Button
        >
        <Button
          :disabled="btn10"
          type="primary"
          @click="onClick('TransferOut')"
          size="small"
          >转出</Button
        >
        <Button
          :disabled="btn11"
          type="primary"
          @click="onClick('Consult')"
          size="small"
          >咨询</Button
        >
      </div>
      <div style="flex: 1; margin-right: 20px">
        <Form :model="config" label-position="left" :label-width="100">
          <FormItem label="服务器">
            <Input v-model="config.ip"></Input>
          </FormItem>
          <FormItem label="端口">
            <Input v-model="config.port"></Input>
          </FormItem>
          <FormItem label="集团号">
            <Input v-model="config.vccId"></Input>
          </FormItem>
          <FormItem label="工号">
            <Input v-model="config.agentId"></Input>
          </FormItem>
          <FormItem label="座席密码">
            <Input v-model="config.password"></Input>
          </FormItem>
          <FormItem v-if="config.tenantType == '1'" label="密钥">
            <Input v-model="config.loginKey"></Input>
          </FormItem>
          <FormItem label="租户类型">
            <Select v-model="config.tenantType" @on-change="onChange">
              <Option
                v-for="item in tenantTypes"
                :value="item.value"
                :key="item.value"
                >{{ item.label }}</Option
              >
            </Select>
          </FormItem>
        </Form>
      </div>
    </div>

    <Modal v-model="makeCallModal" title="外呼" @on-ok="ok" @on-cancel="cancel">
      <Input
        v-model="telPhone"
        placeholder="输入号码"
        style="width: 300px; margin-bottom: 20px"
      />
      <Input
        v-model="displayNum"
        placeholder="外显号码"
        style="width: 300px; margin-bottom: 20px"
      />
      <Select v-model="av" style="width: 300px; margin-bottom: 20px">
        <Option :value="1" :key="1">音频</Option>
        <Option :value="2" :key="2">视频</Option>
      </Select>
    </Modal>

    <Modal
      v-model="consultModal"
      title="咨询"
      @on-ok="consultOk"
      @on-cancel="cancel"
    >
      <Input
        v-model="consultNum"
        placeholder="输入号码"
        style="width: 300px; margin-bottom: 20px"
      />
      <Select v-model="consultType" style="width: 300px; margin-bottom: 20px">
        <Option :value="0" :key="0">座席工号</Option>
        <Option :value="1" :key="1">外部号码</Option>
        <Option :value="2" :key="2">服务号</Option>
      </Select>
    </Modal>
    <Modal
      v-model="transferOutModal"
      title="转出"
      @on-ok="transferOutOk"
      @on-cancel="cancel"
    >
      <Input
        v-model="transferOutNum"
        placeholder="输入号码"
        style="width: 300px; margin-bottom: 20px"
      />
      <Select
        v-model="transferOutType"
        style="width: 300px; margin-bottom: 20px"
      >
        <Option :value="0" :key="0">座席工号</Option>
        <Option :value="1" :key="1">外部号码</Option>
        <Option :value="2" :key="2">服务号</Option>
      </Select>
    </Modal>
    <VideoAndShare ref="videoAndShare"></VideoAndShare>
  </div>
</template>

<script>
import VideoAndShare from "./videoAndShare";

export default {
  name: "App",
  components: {
    VideoAndShare,
  },
  data() {
    return {
      load: false,
      makeCallModal: false,
      consultModal: false,
      transferOutModal: false,
      telPhone: "",
      consultNum: "",
      consultType: 1,
      transferOutNum: "",
      transferOutType: 1,
      displayNum: "",
      av: 1,
      nowUIStatusTxt: "",
      statusTime: 0,
      statusTimer: null, //状态切换计时 定时器
      config: {
        tenantType: 0,
        ip: "iccs.pointlinkprox.com",
        port: "5049",
        vccId: "500610",
        agentId: "1089",
        password: "Zyzx@10086",
        loginKey: "",
        event: {
          OnInitalSuccess: this._OnInitalSuccess,
          OnInitalFailure: this._OnInitalFailure,
          OnAnswerCall: this._OnAnswerCall,
          OnCallEnd: this._OnCallEnd,
          OnReportBtnStatus: this._OnReportBtnStatus,
          OnCallRing: this._OnCallRing,
          OnBarExit: this._OnBarExit,
          OnUpdateVideoWindow: this._OnUpdateVideoWindow,
          OnAgentWorkReport: this._OnAgentWorkReport,
        },
      },
      tenantTypes: [
        {
          value: 0,
          label: "C",
        },
        {
          value: 1,
          label: "Y",
        },
      ],
      VccBar: null,
      signIn: false,
      signInDesc: "未登录",
      btn1: true, //示忙
      btn2: true, //示闲
      btn3: true, //呼出
      btn4: true, //保持
      btn5: true, //接回
      btn6: true, //挂断
      btn8: true, //会议
      btn9: true, //应答
      btn10: true, //转出
      btn11: true, //咨询
      workStatus: "",
    };
  },
  created() {},
  methods: {
    consultOk() {
      this.VccBar.Consult(this.consultType, this.consultNum);
    },
    consultCancel() {
      this.consultModal = false;
    },
    transferOutOk() {
      this.VccBar.TransferOut(this.consultType, this.consultNum);
    },
    transferOutCancel() {
      this.transferOutModal = false;
    },
    _setStatusTime(type) {
      if (type == 1) {
        this.statusTime = 0;
        this.statusTimer = clearInterval(this.statusTimer);
        this._setStatusTime();
      } else {
        this.statusTimer = setTimeout(() => {
          this.statusTime++;
          this._setStatusTime();
        }, 1000);
      }
      window.parent.postMessage(
        {
          type: "statusTime",
          workStatus: this.workStatus,
          nowUIStatusTxt: this.nowUIStatusTxt,
          statusTime: this.statusTime,
          btnAll: {
            btn1: this.btn1,
            btn2: this.btn2,
            btn3: this.btn3,
            btn4: this.btn4,
            btn5: this.btn5,
            btn6: this.btn6,
            btn8: this.btn8,
            btn9: this.btn9,
            btn10: this.btn10,
            btn11: this.btn11,
          },
        },
        "*"
      );
    },
    async onChange(value) {
      this.VccBar = VccBar.setConfig(this.config).client();
      await this.VccBar.load("cmscVccBar").then(() => {
        this.load = true;
      });
    },
    ok() {
      if (!this.telPhone) {
        return;
      }
      this.VccBar.MakeCall(
        this.telPhone,
        3,
        "",
        "",
        "",
        "",
        "",
        "",
        this.displayNum,
        "",
        this.av
      );
    },
    cancel() {
      this.makeCallModal = false;
    },
    _OnInitalSuccess() {
      this.signIn = true;
      this.signInDesc = "登出";
      this.onParent();
      console.log("初始化成功");
    },
    _OnInitalFailure() {},

    _OnReportBtnStatus(btnIDS) {
      var arrIDS = btnIDS.split("|");
      this.setBtnDisable();
      for (var i = 0; i < arrIDS.length; i++) {
        this["btn" + parseInt(arrIDS[i])] = false;
      }
    },
    setBtnDisable() {
      this.btn1 = true;
      this.btn2 = true;
      this.btn3 = true;
      this.btn4 = true;
      this.btn5 = true;
      this.btn6 = true;
      this.btn8 = true;
      this.btn9 = true;
      this.btn10 = true;
      this.btn11 = true;
    },
    _OnCallRing(
      callingNo,
      calledNo,
      orgCalledNo,
      callData,
      serialID,
      serviceDirect,
      callID,
      userParam,
      taskID,
      userDn,
      agentDn,
      areaCode,
      fileName,
      networkInfo,
      queueTime,
      opAgentID,
      ringTime,
      projectID,
      accessCode,
      taskName,
      cityName,
      userType,
      lastServiceId,
      lastServiceName,
      accessNumber
    ) {},
    _OnAnswerCall(
      userNo,
      answerTime,
      serialID,
      serviceDirect,
      callID,
      userParam,
      taskID,
      av,
      tc,
      haveAsrEvent
    ) {
      if (av === "video") {
        //这里控制视频窗口显示
        this.$refs["videoAndShare"].isVideo = true;
      }
    },
    _OnCallEnd(
      callID,
      serialID,
      serviceDirect,
      userNo,
      bgnTime,
      endTime,
      agentAlertTime,
      userAlertTime,
      fileName,
      directory,
      disconnectType,
      userParam,
      taskID,
      serverName,
      networkInfo
    ) {},
    _OnBarExit(code, description) {
      this.signInDesc = "未登录";
      this.signIn = false;
      this.onParent();
      console.log("坐席迁出");
    },
    _OnUpdateVideoWindow(param) {
      this.$refs.videoAndShare.onUpdateVideoWindow(param);
    },
    _OnAgentWorkReport(workStatus, description) {
      this.workStatus = workStatus;
      this.nowUIStatusTxt = description;
      this._setStatusTime(1);
    },
    async onClick(name) {
      if (!this.load) {
        return;
      }
      switch (name) {
        case "SignIn":
          if (this.signIn) {
            await this.VccBar.UnInitial();
          } else {
            await this.VccBar.Initial();
          }
          this.onParent();
          break;
        case "SetIdle":
          this.VccBar.SetIdle();
          break;
        case "SetBusy":
          this.VccBar.SetBusy(0);
          break;
        case "MakeCall":
          this.makeCallModal = true;
          break;
        case "Answer":
          this.VccBar.Answer();
          break;
        case "Disconnect":
          this.VccBar.Disconnect();
          break;
        case "RetrieveHold":
          this.VccBar.RetrieveHold();
          break;
        case "Hold":
          this.VccBar.Hold();
          break;
        case "Consult":
          this.consultModal = true;
          break;
        case "Conference":
          this.VccBar.Conference();
          break;
        case "TransferOut":
          this.transferOutModal = true;
          break;
      }
    },
    async onInfo() {
      await this.onChange(0);
      await this.onClick("SignIn");
    },
    onDial(row) {
      this.telPhone = row;
      this.av = 1;
      this.ok();
    },
    onParent() {
      window.parent.postMessage(
        {
          type: "logon",
          signIn: this.signIn,
          message: this.signInDesc,
        },
        "*"
      );
    },
  },
  mounted() {
    this.onInfo();
    window.addEventListener("message", (event) => {
      if (event.data?.type == "pointlinkprox") {
        this.onClick(event.data.ClickType);
      }
      if (event.data?.type == "onDial") {
        this.onDial(event.data.telPhone);
      }
    });
  },
  async destroyed() {
    await this.onClick("SignIn");
    if (window?.CmscCall) {
      delete window.CmscCall;
    }
  },
  filters: {
    filterTime(val) {
      let h = parseInt(val / 3600);
      h = h > 9 ? `${h}` : `0${h}`;
      let m = parseInt((val - 3600 * h) / 60);
      m = m > 9 ? `${m}` : `0${m}`;
      let s = val - 3600 * h - 60 * m;
      s = s > 9 ? `${s}` : `0${s}`;
      return `${h}:${m}:${s}`;
    },
  },
};
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}
</style>
