<template>
  <div class="videoWrap"  id = 'agent_webcam' ref="videoWrap"  v-show = 'isVideo'>
    <div class="remoteView" id ="remoteView_cincc_5g" v-show = 'showRemote'></div>
    <div class="selfView" id ="selfView_cincc_5g" v-show = 'showSelf'></div>
    <div class="shareView" id ="shareView_cincc_5g"></div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      isVideo: false,
      showRemote: false,
      showSelf: false,
    }
  },
  created(){

  },
  methods: {
    onUpdateVideoWindow(param) {
      console.log('OnUpdateVideoWindow-----',param)
      if (param.key_word == "GetVideoViews") {
        param.param.SetVideoViews(
          'selfView_cincc_5g',
          'remoteView_cincc_5g',
          'shareView_cincc_5g'
        )
      }else if(param.key_word == "OnGetLocalView"){
        this.showSelf = true
      }
      else if(param.key_word == "OnGetShareView"){
        
      }
      else if(param.key_word == "OnGetRemoteView"){
        this.showRemote = true
        // this.isVideo = true
      }
      else if(param.key_word == "OnRemoveRemoteView"){
        this.showRemote = false
      }else if (param.key_word == 'OnLeaveSuccess') {
        this.showRemote = false
        this.showSelf = false
        this.isVideo = false
      }
    },
  }
}
</script>

<style lang = "less">
.videoWrap {
  height: 580px;
  width: 500px;
  position: absolute;
  z-index: 999999;
  right: 20px;
  box-sizing: content-box;
  box-shadow: 0 0 5px 1px #333;
  background:#000;
  top: 70px;
  .video-top{
    height: 30px;
    line-height:30px;
    text-align: right;
    padding-right:5px;
    /* padding-top:5px; */
  }
  .moveHandle {
    height: 50px;
    background: #000;
    cursor: move;
    width: 500px;
    padding-left:20px;
    padding-top:15px;
    position:absolute;
    bottom:0;
    left:0;
    .icon-wrap{
      height:20px;
      position:relative;
      width:100%;
    }
  }
  .remoteView {
    height: 500px;
    width: 280px;
    position: absolute;
    left: 0;
    /* top: 50px; */
    z-index: 1;
    background: #fff;
  }
  .selfView {
    height: 130px;
    width: 210px;
    position: absolute;
    right: 0;
    /* top: 50px; */
    z-index: 2;
    background: #fff;
  }
  .shareView {
    height: 500px;
    width: 400px;
    position: absolute;
    left: 0;
    top: 50px;
    z-index: 1;
    background: #ffffff;
    display: none;
  }
}
.videoWrapMin{
  height: 230px!important;
  width:200px!important;
  .moveHandle{
    display: none!important;
  }
  .remoteView{
    height: 200px!important;
    width:200px!important;
    top:30px;
    left:0;
    video{
      height:200px!important;
      width:200px!important;
    }
  }
  .selfView{
    display: none!important;
  }
  .shareView{
    display: none!important;
  }
}
.card-wrap {
  height: calc(100% - 85px);
  overflow-y: auto;
}
.card-btn {
  background: #fff;
  border-top: 1px solid #e8eaec;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 20px 20px 30px;
  >button {
    margin-right: 15px;
  }
}
</style>